(function () {
  const utmParameters = ["utm_source", "utm_medium", "utm_campaign", "utm_term", "utm_content", "utm_at"];
  const cookieName = "_stdy_utm_url";

  function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(";").shift();
  }

  function getUtmParamsFromUrl(url) {
    const urlObj = new URL(url);
    const params = new URLSearchParams(urlObj.search);
    const utmParams = {};
    utmParameters.forEach((param) => {
      if (params.has(param)) {
        utmParams[param] = params.get(param);
      }
    });
    return utmParams;
  }

  function appendUtmParamsToLinks(utmParams) {
    const links = document.querySelectorAll(".js-utm-link");
    links.forEach((link) => {
      const linkUrl = new URL(link.href);
      for (const [key, value] of Object.entries(utmParams)) {
        linkUrl.searchParams.set(key, value);
      }
      link.href = linkUrl.toString();
    });
  }

  const utmCookie = getCookie(cookieName);
  if (utmCookie) {
    const utmParamsFromCookie = getUtmParamsFromUrl(utmCookie);
    appendUtmParamsToLinks(utmParamsFromCookie);
  }
})();
